import React, {useState} from 'react';
import Collapse from '@material-ui/core/Collapse';
import withStore from "@/hocs/withStore";
import Svg from "@/Pages/All/Svg";
import LinkLoad from "@/Layouts/LinkLoad";


const FooterNavMobile = ({...props}) => {
    const [checked, setChecked] = useState({
        checked_0: false,
        checked_1: false,
        checked_2: false,
        checked_3: false
    });

    const toggleReviews = (id) => {
        setChecked(prevState => ({
            ...prevState,
            [`checked_${id}`]: !prevState[`checked_${id}`]
        }));
    };

    const {stores = {}} = props;
    const {filterStore = {}} = stores;

    return (
        <>
            <div className={`footer__item`}>
                <div className={`link-title-mobile`}>
                    <LinkLoad href={`/about-company`} className={`link-title`}>
                        О компании
                    </LinkLoad>
                    <div className={`icon${checked.checked_0 ? ' down' : ''}`}
                         onClick={() => toggleReviews(0)}>
                        <Svg id={`keyboardArrowDownOutlinedIcon`}
                             className={`svg__keyboardArrowDownOutlinedIcon`} />
                    </div>
                </div>
                <Collapse in={checked.checked_0}>
                    <LinkLoad href={`/news`} className={`link-item`}>
                        Новости и пресс-релизы
                    </LinkLoad>
                    <LinkLoad href={`/jobs`} className={`link-item`}>
                        Вакансии
                    </LinkLoad>
                    <LinkLoad href={`/philosophy`} className={`link-item`}>
                        Философия
                    </LinkLoad>
                    <LinkLoad href={`/contacts`} className={`link-item`}>
                        Контакты
                    </LinkLoad>
                    <LinkLoad href={`/polzovatelskoe-soglashenie`} className={`link-item`}>
                        Пользовательское соглашение
                    </LinkLoad>
                    <LinkLoad href={`/politika-konfidencialnosti`} className={`link-item`}>
                        Политика конфиденциальности
                    </LinkLoad>
                    <LinkLoad href={`/sitemap`} className={`link-item`}>
                        Карта сайта
                    </LinkLoad>
                </Collapse>
            </div>
            <div className={`footer__item`}>
                <div className={`link-title-mobile`}>
                    <LinkLoad href={`/catalog`}
                          onClick={() => filterStore.handleClickLink()}
                          className={`link-title`}>
                        Украшения
                    </LinkLoad>
                    <div className={`icon${checked.checked_1 ? ' down' : ''}`}
                         onClick={() => toggleReviews(1)}>
                        <Svg id={`keyboardArrowDownOutlinedIcon`}
                             className={`svg__keyboardArrowDownOutlinedIcon`} />
                    </div>
                </div>
                <Collapse in={checked.checked_1}>
                    <LinkLoad href={`/catalog`}
                          onClick={() => filterStore.handleClickLink()}
                          className="link-item">
                        Каталог
                    </LinkLoad>
                    <LinkLoad href={`/catalog/kolca`}
                          onClick={() => filterStore.handleClickLink()}
                          className="link-item">
                        Кольца
                    </LinkLoad>
                    <LinkLoad href={`/catalog/sergi`}
                          onClick={() => filterStore.handleClickLink()}
                          className="link-item">
                        Серьги
                    </LinkLoad>
                    <LinkLoad href={`/catalog/podveski`}
                          onClick={() => filterStore.handleClickLink()}
                          className="link-item">
                        Подвески
                    </LinkLoad>
                    <LinkLoad href={`/catalog/broshi`}
                          onClick={() => filterStore.handleClickLink()}
                          className="link-item">
                        Броши
                    </LinkLoad>
                    <LinkLoad href={`/catalog/cepi`}
                          onClick={() => filterStore.handleClickLink()}
                          className="link-item">
                        Цепи
                    </LinkLoad>
                </Collapse>
            </div>
            <div className={`footer__item`}>
                <div className={`link-title-mobile`}>
                    <LinkLoad href={`/b2b`}
                          className={`link-title`}>
                        Партнерам
                    </LinkLoad>
                    <div className={`icon${checked.checked_2 ? ' down' : ''}`}
                         onClick={() => toggleReviews(2)}>
                        <Svg id={`keyboardArrowDownOutlinedIcon`}
                             className={`svg__keyboardArrowDownOutlinedIcon`} />
                    </div>
                </div>
                <Collapse in={checked.checked_2}>
                    <a href={`https://b2b.platina-kostroma.com/web-create-account`}
                       className={`link-item`}
                       target={`_blank`}>
                        Стать партнёром
                    </a>
                    <a href={`https://b2b.platina-kostroma.com/web-login`}
                       className={`link-item`}
                       target={`_blank`}>
                        Личный кабинет партнера
                    </a>
                </Collapse>
            </div>
            <div className={`footer__item footer__item_bottom-line`}>
                <div className={`link-title-mobile`}>
                    <div className={`link-title`}>
                        Покупателям
                    </div>
                    <div className={`icon${checked.checked_3 ? ' down' : ''}`}
                         onClick={() => toggleReviews(3)}>
                        <Svg id={`keyboardArrowDownOutlinedIcon`}
                             className={`svg__keyboardArrowDownOutlinedIcon`} />
                    </div>
                </div>
                <Collapse in={checked.checked_3}>
                    <LinkLoad href={`/gift-certificate`} className={`link-item`}>
                        Подарочные сертификаты
                    </LinkLoad>
                    <LinkLoad href={`/platina-benefits`} className={`link-item`}>
                        Бонусная программа
                    </LinkLoad>
                    <LinkLoad href={`/sber-credit-terms`} className={`link-item`}>
                        Условия кредитования и рассрочки
                    </LinkLoad>
                    <LinkLoad href={`/dolyame`} className={`link-item`}>
                        Покупка долями
                    </LinkLoad>
                    <LinkLoad href={`/split`} className={`link-item`}>
                        Покупка в сплит
                    </LinkLoad>
                    <LinkLoad href={`/delivery`} className={`link-item`}>
                        Оплата и доставка
                    </LinkLoad>
                    <LinkLoad href={`/vozvrat-tovara`} className={`link-item`}>
                        Возврат товара
                    </LinkLoad>
                    <LinkLoad href={`/quality-guarantee`} className={`link-item`}>
                        Гарантии качества
                    </LinkLoad>
                    <LinkLoad href={`/faq`} className={`link-item`}>
                        FAQ
                    </LinkLoad>
                </Collapse>
            </div>
        </>
    )
};

export default withStore(FooterNavMobile);
